<template>
  <div class="menu-container" :class="{ 'menu-container-collapsed': isCollapse }">
    <a-menu
      v-model="collapsed"
      class="menu"
      v-model:selectedKeys="selectedKeys"
      :openKeys="openKeys"
      mode="inline"
      @click="handleSelect"
      @openChange="onOpenChange"
    >
      <template v-for="menuItem in newMenus">
        <sub-menu
          :key="menuItem.id"
          :menu-info="menuItem"
          :storeBuildTodoCount="storeBuildTodoCount"
          :storeBuildNodeMonitorErrorCount="storeBuildNodeMonitorErrorCount"
        />
      </template>
    </a-menu>
    <div class="collapse-container" @click="toggleCollapsed">
      <div class="collapse-btn">
        <a-icon :type="isCollapse ? 'menu-unfold' : 'menu-fold'" />
      </div>
      <span :class="['collapse-label', { active: !isCollapse }]" v-if="!isCollapse">折叠菜单</span>
    </div>
  </div>
</template>

<script>
import { Menu } from 'ant-design-vue';
import { mapState } from 'vuex';
import { getToken } from '@/util/cookie';
const SubMenu = {
  template: `
      <a-sub-menu :key="menuInfo.id" :dsfsf="menuInfo.id" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <div class="mz-menu-item">
            <MzIcon class="module-icon" :type="menuInfo.icon" />
            <span class="menu-name">{{menuInfo.name}}</span>
            <span v-if="menuInfo.name=='任务中心'&&storeBuildTodoCount>0" style="display: inline-block; width: 8px; height: 8px; background-color: red; border-radius: 50%;margin-left:10px"></span>
          </div>
        </span>
        <template v-for="item in menuInfo.children">
          <a-menu-item v-if="item.children.length == 0" :key="item.id" :dsfsf="item.id">
            <div class="mz-menu-item">
              <MzIcon class="module-icon" :type="item.icon" />
              <span class="menu-name">{{item.name}}</span>
              <a-badge :count="storeBuildTodoCount" style="margin-left:4px" :number-style="{ color: '#fff' }"  v-if="item.name=='待办'" />
              <a-badge :count="storeBuildNodeMonitorErrorCount" style="margin-left:4px" :number-style="{ color: '#fff' }"  v-if="item.name=='节点管控'" />
            </div>
          </a-menu-item>
          <sub-menu :storeBuildTodoCount="storeBuildTodoCount" v-else :key="item.id" :menu-info="item" />
        </template>
      </a-sub-menu>
    `,
  name: 'SubMenu',
  // must add isSubMenu: true
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
    storeBuildTodoCount: {
      type: Number,
      default: 0,
    },
    storeBuildNodeMonitorErrorCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    menuId() {
      let hostName = window.location.hostname;
      const testHosts = ['ykdtest01.ynzyq.net', 'ykdtest01.bjddlm.com'];
      let isPre = testHosts.some((host) => hostName.includes(host));
      return process.env.NODE_ENV === 'development' || isPre ? 1033 : 1042;
    },
  },
};

export default {
  components: {
    'sub-menu': SubMenu,
  },

  data() {
    return {
      collapsed: true,
      matchingUrl: '', //当前页面匹配的菜单路径
      selectedKeys: [],
      openKeys: [],
    };
  },

  created() {
    // const currentMenu = this.findMenuInfo('path', this.$route.fullPath);
    // console.log("🚀 ~ created ~ currentMenu:", currentMenu)
    // if (currentMenu && Object.keys(currentMenu).length) {
    //   this.selectedKeys = [currentMenu.id];
    //   this.openKeys = currentMenu.ids.splice(0, currentMenu.ids.length - 1);
    //   return;
    // }

    // const CACHE_MENU_STATUS =
    //   sessionStorage.getItem('CACHE_MENU_STATUS') &&
    //   JSON.parse(sessionStorage.getItem('CACHE_MENU_STATUS'));
    // if (CACHE_MENU_STATUS) {
    //   this.selectedKeys = CACHE_MENU_STATUS.selectedKeys;
    //   this.openKeys = CACHE_MENU_STATUS.openKeys;
    // }
    let id;
  },

  computed: {
    ...mapState({
      newMenus(state) {
        let ids = [];
        function filterMenu(data) {
          return data.map((item) => {
            if (item.type === 'menu') {
              filterMenu(item.children);
              if (item.children.length) {
                ids.push(item.id);
              }
              return true;
            } else {
              return false;
            }
          });
        }
        filterMenu(state.menu.newMenus || []);
        this.openKeys = ids;
        return state.menu.newMenus;
      },
      isCollapse: (state) => state.menu.isCollapse,
      storeBuildTodoCount: (state) => state.menu.storeBuildTodoCount,
      storeBuildNodeMonitorErrorCount: (state) => state.menu.storeBuildNodeMonitorErrorCount,
      menuData: (state) => state.menu.menuData,
    }),
  },
  watch: {
    'menuData.menuIdB'(val) {
      if (val) {
        this.selectedKeys = [Number(val)];
      }
    },
  },

  methods: {
    onOpenChange(openKeys) {
      this.openKeys = openKeys;
    },
    handleSelect({ key }) {
      this.selectedKeys = [key];
      let menu = this.findMenuInfo('id', key);
      let realUrl = menu.path;

      if (realUrl == '/customBi/home') {
        let token = getToken();
        let domainUrl = '';
        if (process.env.NODE_ENV === 'production') {
          domainUrl = 'https://bi.mzdata.top';
        } else {
          domainUrl = 'http://dev.bi.mzdata.top';
        }
        window.open(`${domainUrl}/transtoken?token=${token}`, '_blank');
        return;
      }
      sessionStorage.setItem(
        'CACHE_MENU_STATUS',
        JSON.stringify({
          selectedKeys: this.selectedKeys,
          openKeys: this.openKeys,
        }),
      );
      this.$emit('selectMenuItem', menu);
      this.$router.push(menu.path);
      // setRecentMenuList(menu);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.$emit('handleCollapse', this.collapsed);
      if (this.collapsed) {
        this.openKeys = [];
      }
    },

    //查找菜单中对应字段的对应项
    findMenuInfo(key, value, source = this.newMenus, childrenKey = 'children') {
      let reslute = {};
      const handleFind = (menuItem) => {
        menuItem.find((item) => {
          if (item[key] == value) {
            reslute = item;
          } else if (item[childrenKey]) {
            handleFind(item[childrenKey]);
          }
        });
      };

      handleFind(source);

      return reslute;
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-container {
  position: relative;
  width: 200px;
  // width: 208px;
  height: calc(100vh - 64px);
  overflow: auto;
  display: flex;
  // 纵向排列
  flex-direction: column;
  background: var(--page-background);

  .ant-menu {
    padding: 0 12px 0 0 !important;
  }

  .module-icon {
    font-size: 16px;
    margin-right: 8px;
  }
  & /deep/ .mz-menu-item {
    display: flex;
    align-items: center;
    position: relative;
    i,
    .menu-name {
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      overflow: hidden;
    }
    .showToDoHint {
      position: relative;
      padding-right: 12px;
      &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        top: 15px;
        right: 0px;
        background: #f5222d;
      }
    }

    .sub-menu-circle {
      position: absolute;
      left: -18px;
      width: 6px;
      height: 6px;
      background: var(--menu-text-color);
      border-radius: 50%;
    }
  }

  & /deep/ .ant-menu-submenu-open {
    .ant-menu-item-selected,
    .ant-menu-submenu-selected,
    .ant-menu-submenu-active {
      .sub-menu-circle {
        background: var(--active-color);
      }
    }
  }
  & /deep/ .ant-menu-item-active {
    .sub-menu-circle {
      background: var(--active-color);
    }
  }

  .current-menu {
    position: sticky;
    top: 0px;
    z-index: 1;
    background: white;
    display: flex;
    align-items: center;
    padding: 16px 0 16px 24px;
    .label {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #212d42;
      line-height: 22px;
      letter-spacing: 0em;
    }
  }

  .collapse-container {
    flex: 0 0 48px;
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 16px 0 28px;
    width: 100%;
    box-shadow: 0px 1px 12px 0px rgba(0, 21, 41, 0.08);
    cursor: pointer;
    .collapse-label {
      margin-left: 8px;
      transition: all 0.2s;
      opacity: 0;
      font-size: 0;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      &.active {
        opacity: 1;
        font-size: 12px;
      }
    }
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }
}

/deep/ .ant-menu-submenu-inline .ant-menu-submenu-arrow {
  transform: rotate(-90deg);
  right: 8px;
}

.menu-container-collapsed {
  width: 88px;
  overflow-x: hidden;

  .current-menu {
    padding-left: 12px;
  }
  & /deep/ .module-icon {
    margin-right: 0 !important;
    line-height: 1 !important;
  }
  & /deep/ .mz-menu-item {
    flex-direction: column;
    align-items: center;
    .menu-name {
      width: 100%;
      margin-top: 4px;
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      line-height: 17px;
      opacity: 1 !important;
      max-width: unset !important;
    }
    .showToDoHint {
      padding-right: 10px;
      &::after {
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-vertical {
    height: 64px;
  }
}
</style>
